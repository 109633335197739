import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from "vuex";
import { uploadFile, getFileUrl } from "@/lib/pinata-proxy";
import { getCategories, getConversionCache, getDNACollectionProcess } from "@/lib/api";
import { queryServicesById } from "@/lib/polkadotProvider/query/services";
import { fromEther, toEther, formatUSDTE } from "@/lib/balance-format";
import { updateService, updateServiceFee } from "@/lib/polkadotProvider/command/services";
import { generalDebounce } from "@/utils";
import DialogErrorBalance from "@/components/Dialog/DialogErrorBalance";
const englishAlphabet = val => val && /^[A-Za-z0-9!@#$%^&*\\(\\)\-_=+:;"',.\\/? ]+$/.test(val) || "This field can only contain English alphabet";
export default {
  name: "EditLabServices",
  components: {
    DialogErrorBalance
  },
  data: () => ({
    document: {
      category: "",
      dnaCollectionProcess: "",
      name: "",
      currency: "USDT.e",
      price: 0,
      qcPrice: 0,
      description: "",
      longDescription: "",
      duration: "",
      durationType: "",
      linkKit: null
    },
    kitPurchaseLink: null,
    imageUrl: "",
    testResultSampleUrl: "",
    files: [],
    testResultSampleFile: [],
    isLoading: false,
    usdRate: 0,
    isUploading: false,
    currencyList: ["USDT.e"],
    currencyType: "",
    listExpectedDuration: ["Hours", "Days"],
    listCategories: [],
    selectExpectedDuration: "",
    dnaCollectionProcessList: [],
    isBiomedical: false,
    isShowError: false,
    fee: 0
  }),
  computed: {
    ...mapState({
      web3: state => state.metamask.web3
    }),
    ...mapGetters({
      api: "substrate/getAPI",
      pair: "substrate/wallet"
    }),
    priceHint() {
      return "".concat(this.document.price, " ").concat(this.document.currency, " = ").concat((this.usdRate.conversion * this.document.price).toFixed(4), " USD");
    },
    qcPriceHint() {
      return "".concat(this.document.qcPrice, " ").concat(this.document.currency, " = ").concat((this.usdRate.conversion * this.document.qcPrice).toFixed(4), " USD");
    },
    fieldRequiredRule() {
      return [val => !!val || "This field is required"];
    },
    linkRules() {
      return [val => /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,7}\b([-a-zA-Z0-9()@:%_.~#?&/=]*)/.test(val) || "Link is invalid"];
    },
    fieldEnglishRules() {
      return [englishAlphabet];
    },
    serviceNameRules() {
      return [val => val && val.length <= 50 || "This field only allows 50 characters."];
    },
    decimalRule() {
      return [val => /^\d*(\.\d{0,3})?$/.test(val) || this.isBiomedical || "This field only allows 3 decimal characters."];
    },
    descriptionRules() {
      return [val => val && val.length <= 100 || "This field only allows 100 characters."];
    },
    fileInputRules() {
      return [value => !Array.isArray(value) || "This field is required", value => !Array.isArray(value) && (value === null || value === void 0 ? void 0 : value.size) < 2000000 || "The total file size uploaded exceeds the maximum file size allowed (2MB)", value => !Array.isArray(value) && (value === null || value === void 0 ? void 0 : value.type) === "application/pdf" || "The files uploaded are not in the supported file formats."];
    }
  },
  watch: {
    category() {
      if (this.document.category == "Covid-19") {
        this.isBiomedical = true;
        this.document.qcPrice = "0";
      } else {
        this.isBiomedical = false;
      }
    },
    document: {
      deep: true,
      immediate: true,
      handler: generalDebounce(async function () {
        await this.getUpdateServiceFee();
      }, 500)
    },
    $route: {
      deep: true,
      immediate: true,
      handler: async function (val) {
        try {
          await this.getService(val.params.id);
        } catch (err) {
          console.error(err);
        }
      }
    }
  },
  async created() {
    this.dnaCollectionProcessList = (await getDNACollectionProcess()).data;
    this.id = this.$route.params.id;
    await this.getServiceCategory();
    await this.getService(this.$route.params.id);
    this.usdRate = await getConversionCache(this.document.currency === "USDT.e" ? "USDT" : this.document.currency, "USD");
  },
  methods: {
    async getService(id) {
      const detailService = await queryServicesById(this.api, id);
      const {
        category,
        description,
        dnaCollectionProcess,
        expectedDuration,
        image,
        longDescription,
        name,
        pricesByCurrency,
        testResultSample
      } = detailService.info;
      const [longDesc, linkKit] = longDescription.split("||");
      const utf8Description = this.web3.utils.isHex(description) ? this.web3.utils.hexToUtf8(description) : description;
      const utf8LongDescription = this.web3.utils.isHex(longDesc) ? this.web3.utils.hexToUtf8(longDesc) : longDesc;
      this.document = {
        category,
        dnaCollectionProcess,
        name,
        description: utf8Description,
        longDescription: utf8LongDescription,
        linkKit: linkKit ? "yes" : "no",
        currency: formatUSDTE(pricesByCurrency[0].currency),
        price: Number(await fromEther(pricesByCurrency[0].priceComponents[0].value.replaceAll(",", ""), pricesByCurrency[0].currency)),
        qcPrice: Number(await fromEther(pricesByCurrency[0].additionalPrices[0].value.replaceAll(",", ""), pricesByCurrency[0].currency)),
        duration: expectedDuration.duration,
        durationType: expectedDuration.durationType
      };
      this.kitPurchaseLink = linkKit !== null && linkKit !== void 0 ? linkKit : "";
      this.imageUrl = image;
      this.testResultSampleUrl = testResultSample;
      if (this.imageUrl) {
        fetch(this.imageUrl).then(res => res.blob()) // Gets the response and returns it as a blob
        .then(blob => {
          this.files.push(new File([blob], this.imageUrl.substring(21)));
        });
      }
      if (this.testResultSampleUrl) {
        await fetch(this.testResultSampleUrl).then(res => res.blob()) // Gets the response and returns it as a blob
        .then(blob => {
          const fileName = this.testResultSampleUrl.split("/").pop();
          this.testResultSampleFile = new File([blob], fileName, {
            type: "application/pdf"
          });
        });
      }
    },
    async getServiceCategory() {
      const {
        data: data
      } = await getCategories();
      this.listCategories = data;
    },
    async getUpdateServiceFee() {
      const {
        category,
        dnaCollectionProcess,
        name,
        currency,
        price,
        qcPrice,
        description,
        longDescription,
        duration,
        durationType
      } = this.document;
      const service = {
        name,
        pricesByCurrency: [{
          currency: formatUSDTE(currency),
          totalPrice: await toEther(price + qcPrice, currency),
          priceComponents: [{
            component: "testing_price",
            value: await toEther(price, currency)
          }],
          additionalPrices: [{
            component: "qc_price",
            value: await toEther(qcPrice, currency)
          }]
        }],
        expectedDuration: {
          duration,
          durationType
        },
        category,
        description,
        longDescription,
        dnaCollectionProcess,
        image: this.imageUrl,
        testResultSample: this.testResultSampleUrl
      };
      const fee = await updateServiceFee(this.api, this.pair, this.$route.params.id, service);
      this.fee = this.web3.utils.fromWei(String(fee.partialFee), "ether");
    },
    closeDialog() {
      this.isShowError = false;
    },
    async updateService() {
      if (this.isLoading) return; // If function already running return.
      if (!this.$refs.addServiceForm.validate()) {
        return;
      }
      this.isLoading = true;
      const {
        category,
        dnaCollectionProcess,
        name,
        currency,
        price,
        qcPrice,
        description,
        longDescription,
        duration,
        durationType
      } = this.document;
      const service = {
        name,
        pricesByCurrency: [{
          currency: formatUSDTE(currency),
          totalPrice: await toEther(price + qcPrice, currency),
          priceComponents: [{
            component: "testing_price",
            value: await toEther(price, currency)
          }],
          additionalPrices: [{
            component: "qc_price",
            value: await toEther(qcPrice, currency)
          }]
        }],
        expectedDuration: {
          duration,
          durationType
        },
        category,
        description,
        longDescription: this.document.linkKit === "yes" ? longDescription + "||" + this.kitPurchaseLink : longDescription,
        dnaCollectionProcess,
        image: this.imageUrl,
        testResultSample: this.testResultSampleUrl
      };
      try {
        await updateService(this.api, this.pair, this.$route.params.id, service, () => {
          this.$router.push("/lab/services");
          this.isLoading = false;
        });
      } catch (err) {
        if (err.message === "1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low") {
          this.isShowError = true;
        }
      }
    },
    async imageUploadEventListener(file) {
      this.isUploading = true;
      this.isLoading = true;
      this.imageUrl = "";
      if (file) {
        if (file.name.lastIndexOf(".") <= 0) {
          return;
        }
        const dataFile = await this.setupFileReader(file);
        const result = await uploadFile({
          title: dataFile.name,
          type: dataFile.type,
          size: dataFile.size,
          file: dataFile
        });
        const link = getFileUrl(result.IpfsHash);
        this.imageUrl = link; // this is an image file that can be sent to server... (convert img to file path)
        this.isUploading = false;
        this.isLoading = false;
      }
    },
    setupFileReader(value) {
      return new Promise((resolve, reject) => {
        const file = value;
        const fr = new FileReader();
        fr.onload = async function () {
          resolve(value);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(file);
      });
    },
    async fileUploadEventListener(file) {
      if (!file || file.size >= 2000000) {
        return;
      }
      this.isUploading = true;
      this.isLoading = true;
      this.testResultSampleUrl = "";
      if (file) {
        if (file.name.lastIndexOf(".") <= 0) {
          return;
        }
        const dataFile = await this.setupFileReader(file);
        const result = await uploadFile({
          title: dataFile.name,
          type: dataFile.type,
          size: dataFile.size,
          file: dataFile
        });
        const link = getFileUrl(result.IpfsHash);
        this.testResultSampleUrl = link; // this is an image file that can be sent to server... (convert img to file path)
        this.isUploading = false;
        this.isLoading = false;
      }
    },
    selectPicture() {
      this.$refs.fileInput.$refs.input.click();
    }
  }
};